import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Naver.css';
import styled from 'styled-components'





let isClick = window.location.pathname;



function Naver() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-dark">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
          <Navbar.Brand className="ml-4" href="/jokes"><Font
            >Harrison Weinreb</Font></Navbar.Brand>
           
            <li className="nav-item active mt-1">
              <Nav.Link className={{ "test": isClick === "/jokes" }} href="/jokes">Jokes</Nav.Link>
            </li>
            <li className="nav-item mt-1">
              <Nav.Link className={{ "test": isClick === "/about" }} href="/about">About</Nav.Link>
            </li>
            <li className="nav-item mt-1">
              <Nav.Link className={{ "test": isClick === "/vids" }} href="/vids">Videos</Nav.Link>
            </li>
            <li className="nav-item mt-1">
              <Nav.Link className={{ "test": isClick === "/contact" }} href="/contact">Contact</Nav.Link>
            </li>
          </ul>
        </div>
      </nav>

    </div>
  );
}



export default Naver;


const Font = styled.div`
  font-size: 1.4em;
  color: #b86843;
  font-family: "Courier New";
  
`;
