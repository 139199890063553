import React from 'react';

import background from "./greyback.jpg";

import styled from 'styled-components'
import './Vids.css';




function Vids() {
    return (


        <Section>
            <FlexSection>
                <Sheader>Just For Laughs: New Faces</Sheader>
                <Vid>
                    <iframe className="dimensions" src="https://www.youtube.com/embed/yVWc16JJ3Ig?" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>


                </Vid>
                <Sheader>Birthday Boy Sketch</Sheader>
                <Vid>
                    <iframe className="dimensions" src="https://www.youtube.com/embed/fB8gltsW8J8?" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </Vid>
                <Sheader>Littering w/ Cameron Bradford</Sheader>
                <Vid>

                    <iframe className="dimensions" src="https://www.youtube.com/embed/AxNoXk7MEtk?" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                </Vid>
                <Sheader>Dinner Party Sketch</Sheader>
                <Vid>
                    <iframe className="dimensions" src="https://www.youtube.com/embed/ZVO-fqOua6o?" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </Vid>
                <Sheader>Ice Sketch</Sheader>
                <Vid>
                    <iframe className="dimensions" src="https://www.youtube.com/embed/h-mc2srlp6E?" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                </Vid>
                <Sheader> Loud Neighbor Sketch</Sheader>
                <Vid>
                    <iframe className="dimensions" src="https://www.youtube.com/embed/k49LZb7iRP0?" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                </Vid>
                <Sheader> Crowdwork clip</Sheader>
                <Vid>
                    <iframe className="dimensions" src="https://www.youtube.com/embed/JHt45nFOIro?si=-f6lPlAD1SroKkH3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                </Vid>


            </FlexSection>
        </Section>

    )

}

export default Vids;


const Section = styled.div`


background: url(${background});
  background-repeat: repeat-y;
  background-size: contain;
  background-opacity: 50%;
  @media (max-width: 768px) {
    background-size: contain;
  }
`;


const FlexSection = styled.div`
 
  display: flex;
  flex-direction: column;
  padding-top: 4em;
  border: 2px solid black;

  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    align-items: center;

  }

  
`;


const Sheader = styled.div`
  
  align-self: center;
  width: 50%;
  color: lightgrey;
  opacity: 90%;
  text-align: center;
border: 2px solid #b86843;
margin-bottom: 1em;
margin-top: 1em;
text-shadow: 2px 2px #b86843;
@media (max-width: 768px) {
   

}



`;



const Vid = styled.div`

width: 50%;
position: relative;

padding-top: 25px;
height: 80vh;
@media (max-width: 768px) {
    padding-bottom: 0%;
    padding-top: 0%;
    height: 40vh;
    width: 100%;
    
    border: 3px solid black;

  }

`


