import React from 'react';

import background from "./backgroundME.jpg";

import styled from 'styled-components'



function About() {
  return (

    <div>
      <Section>
        <FlexSection>
          <Par>
            <text>
              Harrison is a stand-up comic, writer, and actor from Montreal, Canada.
              He's been featured at the New York Comedy Fest, Edinburgh Fringe, and
              was recently named a New Face of Comedy at Just for Laughs in 2021.<br></br><br></br>


              His writing has appeared in the Beaverton, CBC comedy,
              Funny or Die and he was named one of the top 20 funniest
              Twitter accounts by Reader’s Digest.
              <br></br><br></br>
              You can catch him performing all around North America and making sketches with his friends on the internet.
            </text>
          </Par>
        </FlexSection>
      </Section>
    </div>
  )

}

export default About;


const Section = styled.div`
  height: 100vh;
  background: url(${background});
  background-size: cover;
`;


const FlexSection = styled.div`
 
  display: flex;
align-items: right; 
  flex-wrap: wrap;
  padding-top: 4em;
  margin-left: 0.5em;
  justify-content: right;

  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    align-self: center;
  
  
`;


const Par = styled.div`
    padding: 0.5em;
  font-size: 1em;
  color: lightgrey;
  opacity: 80%;
  border: 1px solid white;
width: fit-content;
background: rgba(207, 209, 212, 0.2);
max-width: 30em;
margin-right: 11em;
@media (max-width: 768px) {
  justify-self: center;
  margin-right: 0em;
  margin-top: 9em;
  opacity: 100%;
  
  background: rgba(207, 209, 212, 0.5);



`;


