import React from 'react';

import background from "./harrison1.JPG";
import backgroundmobile from "./backgroundformobile.png";
import Tweet from './Tweet';

import styled from 'styled-components'

function Home() {
  return (

    <div>
      <Section>
        <FlexSection>

          <Tweet />
          <Tweet />
          <Tweet />

        </FlexSection>
        <FlexSection>
          <Tweet />
          <Tweet />

          <Tweet />

        </FlexSection>
      </Section>
    </div>
  )

}

export default Home;


const Section = styled.div`
  height: 100vh;
  background: url(${background});
  background-size: cover;
  display: flex; 
  justify-content: space-between;
  @media (max-width: 768px) {
    background: url(${backgroundmobile});
    background-size: cover;
  }
`;


const FlexSection = styled.div`
 
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 4em;
  margin-left: 0.5em;

`;






