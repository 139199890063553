
import React from 'react';
import './App.css';
import Naver from './Naver';
import Home from './Home';
import About from './About';
import Vids from './Vids';
import Contact from './Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <BrowserRouter>

      <Naver></Naver>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/jokes" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/vids" element={<Vids />} />
        <Route exact path="/contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
