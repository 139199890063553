import React from 'react';

import styled from 'styled-components'

import './Tweet.css';

const tweets = [
    "Roger Federer is very competitive. His dad's last name is just Feder.",
    "Airport prices are crazy. 5 billion dollars? For an airport?",
    "Is it just me or has nothing ever happened in Vegas...",
    "A jury of my peers? Wait so you’re telling me these guys are also murderers?",
    "I’m basically the opposite of a celebrity. My own mother said she barely recognized me.",
    "Plumbers are like the black belt of being potty trained.",
    "My wife is making me and her a personalized custom baby.",
    "I skipped a few grades in school. Did not know how to do them.",
    "Flushing the toilet can completely transform the look of your bathroom.",
    "I just met a child prodigy. This guy was unbelievably good at being a child",
    "My barber does 4 hours of calculations and then cuts my hair in one perfect snip",
    "I’m going in for a very risky surgery. There’s a 5% chance of survival. And that’s just for the surgeon.",
    "I became a vegan after seeing how piggy banks are opened.",
    "Give it to me straight, doc. How old am I?",
    "Instead of bringing laptops to a coffee shop why don’t we just bring coffees to Best Buy.",
    "My wife is mad I spent 90 thousand dollars on furniture today so she’s making me sleep on one of our 29 couches.",
    "My parents could learn a thing or two from Santa’s generosity towards me",
    "Sometimes food in the microwave will make an insane noise. It's all good though. Trust the process.",
    "My wife and I are staying together for our nephew",
    "When poison expires, does it become less poisonous or more poisonous?",
    "I know exactly what to get fruit flies for their birthday.",
    "Fruit isn’t healthy. Fruit flies live 48 hours.",
    "It’s great that they give you a hospital gown before surgery. I never know what to wear",
    "Deposited my pay check to the bank and the next day I walk in and see they got a new carpet.",
    "What happened to gum. It used to be so good 45 minutes ago.",
    "If you never go to work, you’ll never work a day in your life.",
    "I love my bodyguard. I would take a bullet for him.",

]



class Tweet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            randomMargin: (Math.floor(Math.random() * 14) + 8),
            randomLeftMargin: (Math.floor(Math.random() * 20) + 5),
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 9000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
      }

    render() {

        const divStyle = {
            "margin-bottom": `${this.state.randomMargin}vh`,
            "margin-left": `${this.state.randomLeftMargin}px`
        };

        return (
            <Styles key={Math.random()} className="tests" style={divStyle} >
                {console.log(divStyle)}
                {tweets[Math.floor(Math.random() * tweets.length)]}
            </Styles>
        );
    };
};

export default Tweet;

const Styles = styled.div`
    padding: 0.5em;
  font-size: 0.8em;
  color: lightgrey;
  
 
width: fit-content;
background: rgba(207, 209, 212, 0.2);
max-width: 35em;
@media (max-width: 768px) {
    font-weight: 600;
    color: black;
    background: rgba(207, 209, 212, 0.5);
  
    margin-right: 1vh;
    
   
   



`;
