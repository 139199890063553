import React from 'react';

import background from "./greyback.jpg";

import styled from 'styled-components'

import './Contact.css';




function Contact() {
    return (


        <Section>
           

                <iframe class="dimensions" src="https://docs.google.com/forms/d/e/1FAIpQLSeJb6do-t0xGjtLt049ECcTezpPyfCXR-SM-fJ7pcjUEVrQow/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

            
        </Section>

    )

}

export default Contact;


const Section = styled.div`
height: 100vh;
width: 100%;
background: url(${background});
background-repeat: repeat-x;
background-size: contain;
background-opacity: 50%;
@media (max-width: 768px) {
    background-repeat: repeat-y;
}

`;

const FlexSection = styled.div`
 height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 4em;
 margin-left: 4vh;
`;

const Par = styled.div`
padding: 0.5em;
font-size: 1em;
color: lightgrey;
opacity: 80%;
border: 1px solid white;
width: fit-content;
background: rgba(207, 209, 212, 0.2);
max-width: 30em;
margin-right: 11em;


`;

const Sheader = styled.div`
   
font-size: 2em;
color: lightgrey;
opacity: 90%;
width: fit-content;
border-bottom: 2px solid #b86843;
margin-bottom: 1em;
margin-top: 1em;
text-shadow: 2px 2px #b86843;



`;





